body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App{
  padding:1rem;
}


.project-wrapper{
  padding:1rem;
}

.logo-wrapper{
  max-width:225px;
  padding:0.5rem 1rem 0 1rem;
}

.login-container{
  display:flex;
  justify-content: center;
  align-items: center;
  height:100vh;
  width:100%;
  text-align: center;
}
.login-box{
  margin:auto;
}
