/* src/pages/NotFound.css */
html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
}

.outer {
  display: table;
  width: 100%;
  height: 100%;
}

.inner {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.not-found-wrapper{
    background:black;
    height:100vh;
    width:100%;
    display:flex;
    justify-content: center;
    align-items: center;
    text-align:center;
}


h1 {
  display: inline-block;
  font-family: Arial, sans-serif;
  position: relative;
  margin: 0;
  color: #fff;
  z-index: 2;
  font-size:10rem;
}

.bubble {
    position: absolute;
    border-radius: 100%;
    border: 1px solid #fff;
    bottom: 1px;
    cursor: pointer; /* Add this to make bubbles clickable */
  }

h2, h3 {
  color: #fff;
  font-family: Arial, sans-serif;
  max-width:375px;
  margin:auto;
  font-weight:300;
  font-size:1.25rem;
  line-height:1.3;
}



.logo-container {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    transition: transform 0.1s ease, width 0.1s ease, height 0.1s ease; /* Added width and height transitions */
  }
  